<template>
  <bg-page>
    <div class="Box">
      <div class="limitBox">
        <div>
          <FirstTitle :myTitle="state.FirstTitle"></FirstTitle>
        </div>
        <div class="SearchBox">
          <div>
            <span class="iconfont iconsousuo"></span>
            <input
              placeholder="请输入关键词搜索"
              type="search"
              v-model="state.title"
            />
          </div>
          <div @click="Search">
            <span>搜索</span>
          </div>
        </div>
        <div class="FilterType">
          <FilterType
            :data="state.FilterSearch"
            @GetTypeIndex="GetTypeIndex"
          ></FilterType>
        </div>
        <red-divider />
        <div>
          <DataListComp
            :dataList="state.dataList"
          ></DataListComp>
        </div>
        <!--分页-->
        <Pagination
          :config="state.PageConfig"
          @handleCurrentPage="handleCurrentPage"
        ></Pagination>
      </div>
    </div>
  </bg-page>
</template>
<script>
import * as Vue from "vue";
import FirstTitle from "@/components/FirstTitle/FirstTitle";
import FilterType from "@/components/FilterType/FilterType";
import Pagination from "@/components/Pagination/Pagination";
import DataListComp from "./Components/DataListComp";
import api from "@/comment/request/api";
import { useRoute } from "vue-router";
export default {
  name: "Local",
  components: {
    FirstTitle,
    FilterType,
    Pagination,
    DataListComp,
  },
  setup(props, { attrs, slots, emit }) {
    const route = useRoute();
    const state = Vue.reactive({
      FirstTitle: {
        ChinaName: "地方资讯",
        EnglishName: "Local List",
      },
      FilterSearch: [
        {
          typeName: "所属区域",
          typeList: [],
        },
      ],
      title: "",
      code: null,
      PageConfig: {
        currentPage: 1,
        total: 10,
        pageNum: 5,
      },
      dataList: [],
    });
    // 地区
    api.GetRegionList().success((res) => {
      state.FilterSearch[0].typeList = [{value: null, label: '全部'}].concat(res.data.data);
    });
    const GetTypeIndex = (index, item) => {
      state.code = item.value;
      state.title = "";
      getDataList();
    };
    // 分页
    const handleCurrentPage = (index) => {
      state.PageConfig.currentPage = index;
      getDataList();
    };
    // 列表
    const getDataList = () => {
      const params = {
        page: state.PageConfig.currentPage,
        limit: state.PageConfig.pageNum,
        code: state.code,
        title: state.title,
      };
      api.GetLocalList(params).success((res) => {
        state.dataList = res.data.data.data;
        state.PageConfig.total = parseInt(res.data.data.total);
      });
    };
    // 搜索
    const Search = () => {
      getDataList();
    };
    getDataList();
    return {
      state,
      GetTypeIndex,
      handleCurrentPage,
      Search,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "/src/comment/comment";
.Box {
  .SearchBox {
    margin-bottom: 10px;
  }
}
</style>
